import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SIMPLIFIED_LIST_FEATURE_KEY } from '../keys';
import { SimplifiedListState } from '../reducers/simplified-list.reducers';

export const selectState = createFeatureSelector<SimplifiedListState>(SIMPLIFIED_LIST_FEATURE_KEY);
export const selectItems = createSelector(selectState, (state) => state.items);
export const selectQuery = createSelector(selectState, (state) => state.query);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectTotalItems = createSelector(selectState, (state) => state.totalItems);
