import { Params } from '@angular/router';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SimplifiedListItemModel } from '../models';
import { HttpErrorResponse } from '@angular/common/http';

export const SimplifiedListActions = createActionGroup({
    source: 'SimplfiedList',
    events: {
        'Get Simplified List': props<{ query: Params }>(),
        'Get Simplified List Success': props<{ items: SimplifiedListItemModel[]; totalItems: number }>(),
        'Get Simplified List Error': props<{ error: HttpErrorResponse }>(),
        'Download Simplified': props<{ query: Params }>(),
        'Download Simplified Success': props<{ name: string; blob: Blob }>(),
        'Download Simplified Error': props<{ error: HttpErrorResponse }>(),
        Clear: emptyProps(),
    },
});
