import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectItems, selectLoaded, selectLoading, selectTotalItems } from '../selectors/simplified-list.selectors';
import { filter } from 'rxjs';
import { Params } from '@angular/router';
import { SimplifiedListActions } from '../actions/simplified-list.actions';

@Injectable({
    providedIn: 'root',
})
export class SimplifiedListFacade {
    private readonly store = inject(Store);
    items$ = this.store.select(selectItems).pipe(filter((items) => items !== null));
    totalItems$ = this.store.select(selectTotalItems).pipe(filter((totalItems) => totalItems !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((loaded) => loaded != null));
    loading$ = this.store.select(selectLoading).pipe(filter((loading) => loading != null));

    getSimplifiedList(query: Params): void {
        this.store.dispatch(SimplifiedListActions.getSimplifiedList({ query }));
    }
    exportSimplifiedList(query: Params): void {
        this.store.dispatch(SimplifiedListActions.downloadSimplified({ query }));
    }
    clear() {
        this.store.dispatch(SimplifiedListActions.clear());
    }
}
